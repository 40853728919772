var __jsx = React.createElement;
import React, { useState } from 'react';
import { getYear } from '../../../../utils/utilityFunction';
import { FooterCopyright̽����Ƶ } from '../../../../constants/labels/en';
import Para from '../../../atoms/Para';
var Copyright̽����Ƶ = function Copyright̽����Ƶ() {
  var _useState = useState(getYear()),
    year = _useState[0];
  return __jsx("div", {
    className: "nva-footer__copyright"
  }, __jsx(Para, {
    className: "nva-footer__copyright-text u-caption",
    removeDefaultStyles: true
  }, FooterCopyright̽����Ƶ.copyrightSymbol, " ", FooterCopyright̽����Ƶ.copyright, " ", year));
};
export default Copyright̽����Ƶ;